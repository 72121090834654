
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import BatchDrawer from '@/layout/header/partials/batch/BatchUpdateDrawer.vue';
import moment from 'moment';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'batch-list',
  components: {
    Form,
    Field,
    Datatable,
    BatchDrawer,
    Multiselect,
  },
  data() {
    return {
      moment: '' as any,
      batch: {
        entity_id: [] as any,
        tranche_id: [] as any,
        institute_id: '',
        course_id: '',
      },
       optionsTranche: [] as any,
      tranches_info: [] as any,
      courseInfoData: [] as any,
      entityInfos: [],
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      optionsTPartner:[] as any,
      course_Info: [],
      batchInfo: [],
      instituteList: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'SL',
          key: 'sl',
          sortable: false,
          width: '5px',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: false,
          width: '220px',
        },
        {
          name: 'Training Partner',
          key: 'entity',
          sortable: false,
          width: '220px',
        },
        {
          name: 'Institute Name',
          key: 'ins_name',
          sortable: false,
          width: '220px',
        },
        {
          name: 'Course Name',
          key: 'course_name',
          sortable: false,
          width: '200px',
        },
        {
          name: 'Batch Name',
          key: 'batch_number',
          sortable: false,
        },

        {
          name: 'Total Term',
          key: 'term',
          sortable: false,
          width: '100px',
        },
        {
          name: 'Start Date',
          key: 'start_date',
          sortable: false,
          width: '120px',
        },
        {
          name: 'End Date',
          key: 'end_date',
          sortable: false,
          width: '120px',
        },
        {
          name: 'Trainee Count',
          key: 'trainee_count',
          sortable: false,
        },
        // {
        //   name: "Lead Trainer",
        //   key: "lead_trainer",
        //   sortable: true,
        //   width: "150px",
        // },
        // {
        //   name: "Associate Trainer",
        //   key: "associate_trainer",
        //   sortable: true,
        //   width: "170px",
        // },
      ],

      componentKey: 0,
      actionEdit: false,
      loading: false,
       load: false,
      actionDelete: false,
      batchInfoData: false,
      showCourseNotice: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getAssociation();
    await this.getTranche();
    await this.actioncheck();
    this.emitter.on('batch-list-updated', async () => {
      await this.getBatchList();
    });
  },
   watch: {
    "batch.entity_id": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].heading == 'Batch') {
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Delete') {
                this.actionDelete = true;
              }
            }
          }
        }
      }
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          ApiService.delete('batch/delete/' + `${id}`)
            .then((response) => {
              this.loading = false;
              if (response.data.status == 'error') {
                Swal.fire('Error!', response.data.message, 'error');
              } else {
                Swal.fire('Deleted!', response.data.message, 'success').then(
                  () => {
                    this.emitter.emit('batch-list-updated', true);
                  }
                );
              }
            })
            .catch(({ response }) => {
              console.log(response);
              this.loading = false;
            });
        }
      });
    },
    async courseInfo() {
      this.loading = true;
      let entity_id = this.batch.entity_id;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.course_Info = response.data.data;
          this.batchInfoData = true;
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
        });
    },
    async getBatchList() {
      this.loading = true;
      let institute_info_id = this.batch.institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.batch.tranche_id && this.batch.entity_id) {
        await ApiService.get(
          'batch/list' +
            '?entity_id=' +
            this.batch.entity_id +
            '&tranche_id=' +
            this.batch.tranche_id +
            '&course_info_id=' +
            this.batch.course_id +
            '&institute_info_id=' +
            institute_info_id
        )
          .then((response) => {
            this.loading = false;
            this.componentKey += 1;
            this.batchInfo = response.data.data;
            this.batchInfoData = true;
          })
          .catch(({ response }) => {
            this.loading = false;
            console.log(response);
          });

        this.showCourseNotice = true;
      } else {
        this.loading = false;
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      }
    },
    edit(data) {
      this.emitter.emit('batch-update', data);
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
